import React from "react";
import * as prismic from "@prismicio/client";

// import { PublicFooter } from 'components/Footer';

import { useParams } from "react-router-dom";
import { addNotification } from "../../components/Notifications";
const apiEndpoint = "https://srpinfield.cdn.prismic.io/api/v2";
const Client = prismic.createClient(apiEndpoint);

const SupportArticle = () => {
	let [page, setPage] = React.useState(null);
	let { id } = useParams();
	React.useEffect(() => {
		const fetchData = async () => {
			await Client.getByUID("support", id)
				.then(function (response) {
					var doc = response;

					// doc contains the document content

					setPage(doc);
				})
				.catch(() => {
					addNotification({
						title: "Version Does Not Exist",
						text: "The release notes for the version you have entered does not exist",
						type: "error",
						time: 5000,
					});
				});
		};
		fetchData();
	}, [id]);

	const renderHTML = (element, index) => {
		switch (element.type) {
			case "image":
				return (
					<figure>
						<img
							className="w-full rounded-lg"
							src={element.url}
							alt={element.alt}
							width={1310}
							height={873}
						/>
						<figcaption>{element.alt}</figcaption>
					</figure>
				);
			case "heading2":
				return (
					<h2 key={index} className="dark:text-white">
						{element.text}
					</h2>
				);
			case "paragraph":
				return <p key={index}>{element.text}</p>;
			case "o-list-item":
				return (
					<li key={index} className="dark:text-white">
						{element.text}
					</li>
				);

			default:
				break;
		}
	};
	return (
		<div className="relative py-16 bg-white dark:bg-gray-900 overflow-hidden">
			<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
				<div
					className="relative h-full text-lg max-w-prose mx-auto"
					aria-hidden="true"
				>
					<svg
						className="absolute top-12 left-full transform translate-x-32"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
						/>
					</svg>
					<svg
						className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="f210dbf6-a58d-4871-961e-36d5016a0f49"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
						/>
					</svg>
					<svg
						className="absolute bottom-12 left-full transform translate-x-32"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="d3eb07ae-5182-43e6-857d-35c643af9034"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
						/>
					</svg>
				</div>
			</div>
			<div className="relative px-4 sm:px-6 lg:px-8">
				<div className="text-lg max-w-prose mx-auto">
					<h1>
						<span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
							SRP inField Support
						</span>
						<span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
							{page && page.data && page.data.title[0].text}
						</span>
					</h1>
				</div>
				<div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
					{page &&
						page.data &&
						page.data.article &&
						page.data.article.map((element, index) =>
							renderHTML(element, index)
						)}
				</div>
			</div>
		</div>
	);
};
export default SupportArticle;
