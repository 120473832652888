/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Switch as SwitchCode, Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Form({ onSubmit, children }) {
	return (
		<form
			onSubmit={(e) => {
				onSubmit(e);
			}}
		>
			{children}
		</form>
	);
}

Form.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export const Select = ({
	name,
	description,
	options,
	onChange,
	defaultValue = "",
}) => {
	const [selected, setSelected] = useState(null);
	const [query, setQuery] = useState("");

	const filteredOptions =
		query === ""
			? options
			: options.filter((project) =>
					project.name.toLowerCase().startsWith(query.toLowerCase())
			  );

	// call onChange when the selected value changes

	useEffect(() => {
		if (selected !== null) {
			onChange(selected);
		}
	}, [selected]);

	return (
		<Combobox
			as="div"
			value={selected}
			onChange={(e) => {
				setSelected(e);
			}}
		>
			<Combobox.Label className="block text-sm font-medium text-gray-700">
				{name}
			</Combobox.Label>
			<div className="relative mt-1 space-y-1">
				<Combobox.Input
					value={query}
					className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(item) => (item ? item.name : defaultValue)}
					autocomplete="off"
				/>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</Combobox.Button>

				{options.length > 0 && (
					<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredOptions.map((option) => {
							return (
								<Combobox.Option
									key={option.name}
									value={option}
									className={({ active }) =>
										classNames(
											"relative cursor-default select-none py-2 pl-3 pr-9",
											active ? "bg-indigo-600 text-white" : "text-gray-900"
										)
									}
								>
									{({ active, selected }) => (
										<>
											<span
												className={classNames(
													"block truncate",
													selected && "font-semibold"
												)}
											>
												{option.name}
											</span>

											{selected && (
												<span
													className={classNames(
														"absolute inset-y-0 right-0 flex items-center pr-4",
														active ? "text-white" : "text-indigo-600"
													)}
												>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											)}
										</>
									)}
								</Combobox.Option>
							);
						})}
					</Combobox.Options>
				)}
			</div>
			{!selected && (
				<p className="text-gray-500 text-sm">
					Start typing or click the arrows for options
				</p>
			)}
			{description && <p className="text-gray-500">{description}</p>}
		</Combobox>
	);
};

Select.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultValue: PropTypes.string,
};

export const Switch = ({ label, info, srValue, onChange, defaultValue }) => {
	const [enabled, setEnabled] = useState(defaultValue);

	return (
		<SwitchCode.Group as="div" className="flex items-center justify-between">
			{(label || info) && (
				<SwitchCode.Label as="span" className="flex-grow flex flex-col" passive>
					{label && (
						<span className="text-sm dark:text-white text-gray-900">
							{label}
						</span>
					)}

					{info && <span className="text-sm text-gray-500">{info}</span>}
				</SwitchCode.Label>
			)}

			<SwitchCode
				checked={enabled}
				onChange={() => {
					setEnabled(!enabled);
					if (onChange) {
						onChange(!enabled);
					}
				}}
				className={classNames(
					enabled ? "bg-indigo-600" : "bg-gray-200 dark:bg-gray-800",
					"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				)}
			>
				<span className="sr-only">{srValue}</span>
				<span
					aria-hidden="true"
					className={classNames(
						enabled ? "translate-x-5" : "translate-x-0",
						"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
					)}
				/>
			</SwitchCode>
		</SwitchCode.Group>
	);
};

Switch.propTypes = {
	label: PropTypes.string,
	info: PropTypes.string,
	srValue: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	defaultValue: PropTypes.string,
};

export const Input = (props) => {
	return (
		<div className={props.className}>
			<label
				htmlFor={props.label}
				className="block text-sm font-medium text-gray-700 dark:text-gray-300"
			>
				{props.label}
			</label>
			<div className="mt-1 relative rounded-md shadow-sm  flex items-baseline ">
				{props.icon && (
					<div className="absolute inset-y-0 left-0 pl-3 flex items-center align-middle pointer-events-none">
						<i
							className={`h-5 w-5 text-gray-400 fad fa-${props.icon}`}
							aria-hidden="true"
						/>
					</div>
				)}
				<input
					disabled={props.disabled}
					type={props.type ? props.type : "text"}
					name={props.label}
					className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm  border-gray-300  dark:bg-gray-900 dark:text-white rounded-md ${
						props.icon && "pl-10"
					}`}
					placeholder={props.placeholder}
					required={props.required}
					onChange={(e) => {
						if (props.onChange) {
							props.onChange(e);
						}
					}}
					onBlur={(e) => {
						if (props.onBlur) {
							props.onBlur(e);
						}
					}}
					value={props.value}
					step={props.step && props.step}
					autoComplete={props.autoComplete || "on"}
				/>
			</div>
			{props.helper && (
				<p className="mt-1 text-sm text-gray-500">{props.helper}</p>
			)}
		</div>
	);
};

Input.propTypes = {
	className: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	helper: PropTypes.string,
	icon: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	require: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	autoComplete: PropTypes.string,
	value: PropTypes.string,
	step: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
};

export const Checkbox = ({
	required,
	label,
	description,
	onChange,
	checkedValue = false,
}) => {
	let [checked, setChecked] = React.useState(checkedValue);
	return (
		<div className="relative flex items-start">
			<div className="flex items-center h-5">
				<input
					onChange={() => {
						if (onChange) {
							setChecked(!checked);
							onChange(!checked);
						}
					}}
					checked={checked}
					required={required}
					id={label || "checkbox"}
					name="offers"
					type="checkbox"
					className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 dark:bg-gray-800 rounded"
				/>
			</div>
			{(label || description) && (
				<div className="ml-3 text-sm">
					{label && (
						<label
							htmlFor="offers"
							className="font-medium text-gray-700 dark:text-white"
						>
							{label}
						</label>
					)}
					{description && <p className="text-gray-500">{description}</p>}
				</div>
			)}
		</div>
	);
};

Checkbox.propTypes = {
	required: PropTypes.bool,
	name: PropTypes.string,
	label: PropTypes.string,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	checkedValue: PropTypes.bool,
};

export const Radio = ({ name, label, description, onChange, checked }) => {
	return (
		<div>
			<div className="relative flex items-start">
				<div className="absolute flex items-center h-5">
					<input
						id={name}
						name={name}
						onChange={(e) => {
							if (onChange) {
								onChange(e);
							}
						}}
						checked={checked}
						aria-describedby="privacy_private-to-project_description"
						type="radio"
						className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
					/>
				</div>
				<div className="pl-7 text-sm">
					<label
						htmlFor="privacy_private"
						className="font-medium text-gray-900 dark:text-white"
					>
						{label}
					</label>
					<p id="privacy_private_description" className="text-gray-500">
						{description}
					</p>
				</div>
			</div>
		</div>
	);
};

Radio.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool,
};

export const FieldSet = ({ legend, description, children }) => {
	return (
		<fieldset>
			<legend className="text-sm font-medium text-gray-900 dark:text-white">
				{legend}
			</legend>
			{description && (
				<label className="block text-sm font-light text-gray-700 dark:text-white">
					{description}
				</label>
			)}

			<div className="mt-2 space-y-5">{children}</div>
		</fieldset>
	);
};

FieldSet.propTypes = {
	legend: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export const TextArea = ({
	value,
	label,
	onChange,
	onBlur,
	row = 3,
	defaultValue,
	required,
}) => {
	return (
		<div className="sm:col-span-6">
			<label
				htmlFor={label}
				className="block text-sm font-medium text-gray-700 dark:text-white"
			>
				{label}
			</label>
			<div className="mt-1">
				<textarea
					onChange={(e) => {
						onChange(e);
					}}
					onBlur={(e) => {
						if (onBlur) {
							onBlur(e);
						}
					}}
					id={label}
					name={label}
					rows={row}
					value={value && value}
					required={required}
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-gray-900 dark:text-white"
					defaultValue={defaultValue}
				/>
			</div>
		</div>
	);
};

TextArea.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	row: PropTypes.number,
	defaultValue: PropTypes.string,
	required: PropTypes.bool,
};

export const Time = () => {
	return (
		<div className="mt-2 p-5 w-40 bg-white dark:bg-gray-900 dark:text-white rounded-lg">
			<div className="flex">
				<select
					name="hours"
					className="bg-transparent text-xl appearance-none outline-none"
				>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
					<option value="9">9</option>
					<option value="10">10</option>
					<option value="11">10</option>
					<option value="12">12</option>
				</select>
				<span className="text-xl mr-3">:</span>
				<select
					name="minutes"
					className="bg-transparent text-xl appearance-none outline-none mr-4"
				>
					<option value="0">00</option>
					<option value="30">30</option>
				</select>
				<select
					name="ampm"
					className="bg-transparent text-xl appearance-none outline-none"
				>
					<option value="am">AM</option>
					<option value="pm">PM</option>
				</select>
			</div>
		</div>
	);
};

export const FileUpload = ({ onFiles }) => {
	let [files, setFiles] = useState([]);

	React.useEffect(() => {
		if (files.length > 0) {
			onFiles(files);
		}
	}, [files]);

	return (
		<div
			className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
			onDragOver={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			onDrop={(e) => {
				e.stopPropagation();
				e.preventDefault();

				// update files

				setFiles(e.dataTransfer.files);
			}}
		>
			<div className="space-y-1 text-center">
				<svg
					className="mx-auto h-12 w-12 text-gray-400"
					stroke="currentColor"
					fill="none"
					viewBox="0 0 48 48"
					aria-hidden="true"
				>
					<path
						d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<div className="flex text-sm text-gray-600">
					<label
						htmlFor="file-upload"
						className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
					>
						<span>Upload a file</span>
						<input
							id="file-upload"
							name="file-upload"
							type="file"
							className="sr-only"
							onChange={(e) => {
								setFiles(e.target.files);
							}}
						/>
					</label>
					<p className="pl-1">or drag and drop</p>
				</div>
				<p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
			</div>
		</div>
	);
};

FileUpload.propTypes = {
	onFiles: PropTypes.func.isRequired,
};
