import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Bounds, BakeShadows } from "@react-three/drei";

export default function ThreeRender() {
  return (
    <Canvas
      className="w-full h-full sm:rounded-lg"
      orthographic
      shadows
      dpr={[1, 2]}
      camera={{ position: [10, 10, 10], zoom: 10 }}
    >
      <color attach="background" args={["#fff"]} />
      <ambientLight intensity={0.01} />
      <hemisphereLight intensity={0.125} color="#15803d" groundColor="red" />
      <spotLight
        castShadow
        color="#15803d"
        intensity={2}
        position={[-50, 50, 40]}
        angle={0.25}
        penumbra={1}
        shadow-mapSize={[128, 128]}
        shadow-bias={0.00005}
      />
      <Suspense fallback={null}>
        <Bounds fit clip observe margin={1}>
          <mesh>
            <boxGeometry />
            <meshStandardMaterial />
          </mesh>
        </Bounds>
        <BakeShadows />
      </Suspense>
      <OrbitControls />
    </Canvas>
  );
}
