import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
	createHttpLink,
	from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

//import { toast } from "react-toastify";

let uri = "";
if (process.env.REACT_APP_STAGE === "dev") {
	uri = "http://localhost:4000/dev/graphql";
} else if (process.env.REACT_APP_STAGE === "staging") {
	uri =
		"https://f20vykx6wh.execute-api.us-east-1.amazonaws.com/staging/graphql";
} else if (process.env.REACT_APP_STAGE === "clerking-test") {
	uri =
		"https://yiuo3nvbm4.execute-api.us-east-1.amazonaws.com/clerking-test/graphql";
} else {
	uri = "https://yawlbrvguh.execute-api.us-east-1.amazonaws.com/v1/graphql";
}

export const useApolloClient = ({ authToken = null, removeUser }) => {
	const cache = new InMemoryCache();

	const httpLink = createHttpLink({
		uri: uri,
	});

	const errorLink = onError(({ networkError, operation, forward }) => {
		if (networkError) {
			if (networkError.result?.errors) {
				networkError.result.errors.forEach(({ extensions }) => {
					switch (extensions.code) {
						case "UNAUTHENTICATED":
							removeUser();
							operation.setContext({ skip: true });
							break;

						default:
							break;
					}
				});
			}
		}

		// Call the next link in the chain

		return forward(operation);
	});

	const authAfterware = new ApolloLink((operation, forward) => {
		return forward(operation).map((response) => {
			const { errors } = response;

			if (errors) {
				for (const error of errors) {
					if (error.extensions?.code === "UNAUTHENTICATED") {
						removeUser();

						//toast("Session Expired. Please login again", {
						//	type: "error",
						//});
					}
				}
			}

			return response;
		});
	});

	const authLink = new ApolloLink((operation, forward) => {
		if (authToken) {
			operation.setContext({
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
		}

		return forward(operation);
	});

	const link = from([errorLink, authAfterware, authLink.concat(httpLink)]);

	return new ApolloClient({
		link,
		cache,
	});
};
