import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as prismic from "@prismicio/client";
const apiEndpoint = "https://srpinfield.cdn.prismic.io/api/v2";
const Client = prismic.createClient(apiEndpoint);

export default function SupportPage({ history }) {
	let [categories, setCategories] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				prismic.Predicates.at("document.type", "support_category")
			);
			if (response) {
				setCategories(response.results);
			}
		};
		fetchData();
	}, []);
	return (
		<div className="relative bg-white dark:bg-gray-900 py-16 sm:py-24 lg:py-32">
			<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
					Here to Help
				</h2>
				<p className="mt-2 text-3xl font-extrabold text-gray-900 dark:text-white  tracking-tight sm:text-4xl">
					Everything you need to use SRP inField
				</p>
				<p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
					Choose a support category below to find support articles, written by
					the engineers behind the app, that will help you navigate and use SRP
					in Field.
				</p>
				<div className="mt-12">
					<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
						{categories
							.sort((a, b) => {
								if (a.data.name[0].text > b.data.name[0].text) return 1;
								if (a.data.name[0].text < b.data.name[0].text) return -1;
								return 0;
							})
							.map((category, index) => (
								<div
									key={index}
									className="pt-6"
									onClick={() => {
										history.push(`/help/support/${category.id}`);
									}}
								>
									<div className="flow-root bg-gray-50 dark:bg-gray-800 rounded-lg px-6 pb-8 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer">
										<div className="-mt-6">
											<div>
												<span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
													<i
														className={`h-6 w-6 text-white fas fa-${category.data.icon} fa-lg mt-1`}
														aria-hidden="true"
													/>
												</span>
											</div>
											<h3 className="mt-8 text-lg font-medium text-gray-900 dark:text-white tracking-tight">
												{category.data.name[0].text}
											</h3>
											<p className="mt-5 text-base text-gray-500">
												{category.data.description[0].text}
											</p>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}

SupportPage.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
};
