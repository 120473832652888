/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
	ChartBarIcon,
	CursorClickIcon,
	MenuIcon,
	ShieldCheckIcon,
	ViewGridIcon,
	XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const solutions = [
	{
		name: "Analytics",
		description:
			"Get a better understanding of where your traffic is coming from.",
		href: "#",
		icon: ChartBarIcon,
	},
	{
		name: "Engagement",
		description: "Speak directly to your customers in a more meaningful way.",
		href: "#",
		icon: CursorClickIcon,
	},
	{
		name: "Security",
		description: "Your customers' data will be safe and secure.",
		href: "#",
		icon: ShieldCheckIcon,
	},
	{
		name: "Integrations",
		description: "Connect with third-party tools that you're already using.",
		href: "#",
		icon: ViewGridIcon,
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
	return (
		<Popover className="relative bg-white dark:bg-gray-900">
			{({ open }) => (
				<>
					<div
						className="absolute inset-0 shadow z-30 pointer-events-none"
						aria-hidden="true"
					/>
					<div className="relative z-20">
						<div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
							<div>
								<Link to={""} className="flex">
									<span className="sr-only">SRP inField</span>
									<img
										className="h-8 w-auto sm:h-10"
										src={require("../assets/srp-icon.svg").default}
										alt=""
									/>
								</Link>
							</div>
							<div className="-mr-2 -my-2 md:hidden">
								<Popover.Button className="bg-white dark:bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">Open menu</span>
									<MenuIcon className="h-6 w-6" aria-hidden="true" />
								</Popover.Button>
							</div>
							<div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
								<Popover.Group as="nav" className="flex space-x-10">
									<Link
										to={"/help"}
										className="text-base font-medium text-gray-500 hover:text-gray-900"
									>
										Help Center
									</Link>
									<Link
										to={"/about"}
										className="text-base font-medium text-gray-500 hover:text-gray-900"
									>
										About
									</Link>
								</Popover.Group>

								<Fragment>
									{localStorage.user ? (
										<Link
											to={"/app"}
											className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
										>
											Dashboard
										</Link>
									) : (
										<div className="flex items-center md:ml-12">
											<a
												href={"https://app.srpinfield.com"}
												className="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-white"
											>
												Sign in
											</a>
											<a
												href={"https://app.srpinfield.com/app/auth/register"}
												className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
											>
												Sign up
											</a>
										</div>
									)}
								</Fragment>
							</div>
						</div>
					</div>

					<Transition
						show={open}
						as={Fragment}
						enter="duration-200 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Popover.Panel
							focus
							static
							className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
						>
							<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
								<div className="pt-5 pb-6 px-5 sm:pb-8">
									<div className="flex items-center justify-between">
										<div>
											<img
												className="h-8 w-auto sm:h-10"
												src={require("../assets/srp-icon.svg").default}
												alt="SRP inField"
											/>
										</div>
										<div className="-mr-2">
											<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
												<span className="sr-only">Close menu</span>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</Popover.Button>
										</div>
									</div>
								</div>
								<div className="py-6 px-5">
									<div className="grid grid-cols-2 gap-4">
										<Link
											to={"/help"}
											className="rounded-md text-base font-medium text-gray-900  dark:text-white hover:text-gray-700"
										>
											Help Center
										</Link>

										<Link
											to={"/about"}
											className="rounded-md text-base font-medium text-gray-900  dark:text-white hover:text-gray-700"
										>
											About
										</Link>
									</div>
									<div className="mt-6">
										<Link
											to={"/sign-in"}
											className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
										>
											Sign In
										</Link>
										<p className="mt-6 text-center text-base font-medium text-gray-500">
											Need an Account?{" "}
											<Link
												to={"/register"}
												className="text-indigo-600 hover:text-indigo-500"
											>
												Register
											</Link>
										</p>
									</div>
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
}

export const SupportNavbar = () => {
	let user = JSON.parse(localStorage.getItem("user"));
	return (
		<header>
			<Popover className="relative bg-white dark:bg-gray-900">
				{({ open }) => (
					<>
						<div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
							<div className="flex justify-start lg:w-0 lg:flex-1">
								<Link to="/help">
									<span className="sr-only">SRP inField Support</span>

									<div className="flex-shrink-0 flex items-end px-4 dark:text-white">
										<img
											className="h-8 w-auto sm:h-10"
											src={require("../assets/srp-icon.svg").default}
											alt="Support Center"
										/>
										<h3 className="font-black ml-2 text-indigo-500 dark:text-white leading-4 ">
											Help
											<br />
											Center
										</h3>
									</div>
								</Link>
							</div>
							<div className="-mr-2 -my-2 md:hidden">
								<Popover.Button className="bg-white dark:bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
									<span className="sr-only">Open menu</span>
									<MenuIcon className="h-6 w-6" aria-hidden="true" />
								</Popover.Button>
							</div>
							<Popover.Group as="nav" className="hidden md:flex space-x-10">
								<Popover className="relative">
									{({ open }) => (
										<>
											<Popover.Button
												className={classNames(
													open
														? "text-gray-900 dark:text-white"
														: "text-gray-500",
													"group bg-white dark:bg-gray-900 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900  dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
												)}
											>
												<span>Solutions</span>
												<ChevronDownIcon
													className={classNames(
														open ? "text-gray-600" : "text-gray-400",
														"ml-2 h-5 w-5 group-hover:text-gray-500"
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												show={open}
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													static
													className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
												>
													<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
														<div className="relative grid gap-6 bg-white dark:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
															{solutions.map((item) => (
																<Link
																	key={item.name}
																	href={item.href}
																	className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 dark:hover:text-gray-900"
																>
																	<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white sm:h-12 sm:w-12">
																		<item.icon
																			className="h-6 w-6"
																			aria-hidden="true"
																		/>
																	</div>
																	<div className="ml-4">
																		<p className="text-base font-medium text-gray-900 dark:text-white">
																			{item.name}
																		</p>
																		<p className="mt-1 text-sm text-gray-500">
																			{item.description}
																		</p>
																	</div>
																</Link>
															))}
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>

								<Link
									to="#"
									className="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-white"
								>
									Pricing
								</Link>
								<Link
									to="#"
									className="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-white"
								>
									Partners
								</Link>
								<Link
									to="#"
									className="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-white"
								>
									Company
								</Link>
							</Popover.Group>

							{user ? (
								<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
									<Link
										to="/app"
										className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
									>
										Dashboard
									</Link>
								</div>
							) : (
								<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
									<Link
										to="/sign-in"
										className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-white"
									>
										Sign in
									</Link>
									<Link
										to="#"
										className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
									>
										Sign up
									</Link>
								</div>
							)}
						</div>

						<Transition
							show={open}
							as={Fragment}
							enter="duration-200 ease-out"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="duration-100 ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Popover.Panel
								focus
								static
								className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
							>
								<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-900 divide-y-2 divide-gray-50">
									<div className="pt-5 pb-6 px-5">
										<div className="flex items-center justify-between">
											<div>
												<img
													className="h-8 w-auto sm:h-10"
													src={require("../assets/srp-icon.svg").default}
													alt="SRP inField Help Center"
												/>
											</div>
											<div className="-mr-2">
												<Popover.Button className="bg-white dark:bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
													<span className="sr-only">Close menu</span>
													<XIcon className="h-6 w-6" aria-hidden="true" />
												</Popover.Button>
											</div>
										</div>
										<div className="mt-6">
											<nav className="grid grid-cols-1 gap-7">
												{solutions.map((item) => (
													<Link
														key={item.name}
														href={item.href}
														className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
													>
														<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
															<item.icon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														</div>
														<div className="ml-4 text-base font-medium text-gray-900 dark:text-white">
															{item.name}
														</div>
													</Link>
												))}
											</nav>
										</div>
									</div>
									<div className="py-6 px-5">
										<div className="grid grid-cols-2 gap-4">
											<Link
												to="#"
												className="text-base font-medium text-gray-900 dark:text-white hover:text-gray-700"
											>
												Pricing
											</Link>
											<Link
												to="#"
												className="text-base font-medium text-gray-900 dark:text-white hover:text-gray-700"
											>
												Partners
											</Link>
											<Link
												to="#"
												className="text-base font-medium text-gray-900 dark:text-white hover:text-gray-700"
											>
												Company
											</Link>
										</div>
										<div className="mt-6">
											<Link
												to="/sign-in"
												className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
											>
												Sign In
											</Link>
											<p className="mt-6 text-center text-base font-medium text-gray-500">
												Need an Account?{" "}
												<Link
													to={"/register"}
													className="text-indigo-600 hover:text-indigo-500"
												>
													Register
												</Link>
											</p>
										</div>
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</header>
	);
};
