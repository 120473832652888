// Public Views

import LandingPage from "views/PublicViews/LandingPage";
import Register from "views/PublicViews/Register";
import Login from "views/PublicViews/Login";
import FinishRegistration from "views/PublicViews/FinishRegistration";
import ForgotPassword from "views/PublicViews/ForgotPassword";
import VersionUpdates from "views/PublicViews/VersionUpdates";
import PrivacyPolicy from "views/PublicViews/PrivacyPolicy";
import TOS from "views/PublicViews/TOS";
import { ContactCard } from "views/PublicViews/ContactCard";

// Support Pages

import SupportPage from "views/Support/index.js";
import SupportArticle from "views/Support/SupportArticle";
import SupportCategory from "views/Support/SupportCategory";

const routes = [
	//Public Routes

	{
		path: "/",
		name: "Landing Page",
		component: LandingPage,
		layout: "/",
	},
	{
		path: "/sign-in",
		name: "Sign In",
		component: Login,
		layout: "/",
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		layout: "/",
	},
	{
		path: "/finish-registration/:id",
		name: "Finish Registration",
		component: FinishRegistration,
		layout: "/",
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
		layout: "/",
	},
	{
		path: "/version/:id",
		name: "Version",
		component: VersionUpdates,
		layout: "/",
	},
	{
		path: "/privacy-policy",
		name: "Privacy Policy",
		component: PrivacyPolicy,
		layout: "/",
	},

	{
		path: "/tos",
		name: "Terms Of Service",
		component: TOS,
		layout: "/",
	},
	{
		path: "/contact/:id",
		name: "Contact",
		component: ContactCard,
		layout: "/",
	},

	// Support Views

	{
		path: "/",
		name: "Support Home",
		component: SupportPage,
		layout: "/help",
	},
	{
		path: "/support-article/:id",
		name: "Support Article",
		component: SupportArticle,
		layout: "/help",
	},
	{
		path: "/support/:id",
		name: "Support Category",
		component: SupportCategory,
		layout: "/help",
	},

	// // App Views
	//
	// {
	// 	path: "/",
	// 	name: "Home",
	// 	component: Home,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/project/:id",
	// 	name: "Project",
	// 	icon: "fal fa-project-diagram",
	// 	component: Project,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/space/:id",
	// 	name: "Space",
	// 	icon: "fal fa-project-diagram",
	// 	component: Space,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/:project/note/:id",
	// 	name: "Single Note",
	// 	icon: "fal fa-project-diagram",
	// 	component: SingleNote,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/reporting-center",
	// 	name: "Reporting Center",
	// 	component: ReportingCenter,
	// 	icon: "fad fa-books",
	// 	isMenuItem: true,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/reporting-center/:id",
	// 	component: ProjectReports,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/reporting-center/report/:id",
	// 	component: Report,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/reporting-center/new-report/:id",
	// 	component: NewReport,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/profile",
	// 	name: "User Profile",
	// 	component: Profile,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/equipment",
	// 	name: "Equipment",
	// 	icon: "fad fa-inventory",
	// 	component: Equipment,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// 	active: ["equipment"],
	// },
	// {
	// 	path: "/clients",
	// 	name: "Clients",
	// 	component: Clients,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// 	active: ["clients"],
	// },
	// {
	// 	path: "/clients/:id",
	// 	name: "Clients",
	// 	component: SingleClient,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// 	active: ["clients"],
	// },
	// {
	// 	path: "/equipment/:id",
	// 	name: "Single Equipment",
	// 	icon: "fad fa-inventory",
	// 	component: SingleEquipment,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/notification-center",
	// 	name: "Notification Center",
	// 	component: NotificationCenter,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/project/sample-collection/:id",
	// 	name: "Samples",
	// 	icon: "fal fa-project-diagram",
	// 	component: SampleCollection,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/project/sample-collection/:sampleCollection/:id",
	// 	name: "Samples",
	// 	icon: "fal fa-project-diagram",
	// 	component: SingleSample,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/project/audit/:id",
	// 	name: "Audit",
	// 	icon: "fal fa-project-diagram",
	// 	component: Audit,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/project/audit/:auditID/:id",
	// 	name: "Single Audit",
	// 	icon: "fal fa-project-diagram",
	// 	component: SingleAudit,
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	component: ProjectDailyDocuments,
	// 	path: "/project/project-documents/:id",
	// 	name: "Single Audit",
	// 	icon: "fal fa-project-diagram",
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	component: UserDailyDocuments,
	// 	path: "/project/daily-documents/:id",
	// 	name: "Single Audit",
	// 	icon: "fal fa-project-diagram",
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	component: SingleDailySheet,
	// 	path: "/project/daily-document/:id",
	// 	name: "Single Audit",
	// 	icon: "fal fa-project-diagram",
	// 	layout: "/app",
	// 	noShow: true,
	// },
	// {
	// 	path: "/settings",
	// 	name: "Settings",
	// 	component: Settings,
	// 	layout: "/app",
	// },
	// {
	// 	path: "/project/:id/dry-standards",
	// 	name: "Dry Standards",
	// 	icon: "fad fa-inventory",
	// 	component: DryStandards,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// 	active: ["dryStandard"],
	// },
	// {
	// 	path: "/project/:projectID/dry-standards/:id",
	// 	name: "Dry Standard",
	// 	icon: "fad fa-inventory",
	// 	component: SingleDryStandard,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// 	active: ["singleDryStandard"],
	// },
	//
	// // Subcontractor Admin Views
	//
	// {
	// 	noShow: true,
	// 	path: "/projects",
	// 	name: "Projects",
	// 	icon: "fal fa-user",
	// 	component: SubAdminProjects,
	// 	layout: "/subAdmin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/project/:id",
	// 	name: "Project",
	// 	icon: "fal fa-user",
	// 	component: SubProject,
	// 	layout: "/subAdmin",
	// },
	// {
	// 	path: "/users",
	// 	name: "Subcontractors",
	// 	icon: "fal fa-users",
	// 	component: SubcontractorUsers,
	// 	layout: "/subAdmin",
	// },
	//
	// {
	// 	path: "/user/:id",
	// 	name: "User",
	// 	icon: "fal fa-user",
	// 	component: SubAdminUser,
	// 	layout: "/subAdmin",
	// },
	//
	// // Admin Views
	//
	// {
	// 	path: "/users",
	// 	name: "Users",
	// 	icon: "fal fa-users",
	// 	component: Users,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/user/:id",
	// 	name: "User",
	// 	icon: "fal fa-user",
	// 	component: User,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/locations",
	// 	name: "Location",
	// 	icon: "fal fa-users",
	// 	component: Locations,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/project-roles",
	// 	name: "Project Roles",
	// 	icon: "fal fa-users",
	// 	component: ProjectRolesScreen,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/subcontractors",
	// 	name: "Subcontractors",
	// 	icon: "fal fa-users",
	// 	component: SubcontractorScreen,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/subcontractors/:id",
	// 	name: "Single Subcontractor",
	// 	icon: "fal fa-users",
	// 	component: SingleSubcontractorScreen,
	// 	layout: "/admin",
	// },
	// {
	// 	path: "/subcontractors/:subcontractorID/ratesheet/:id",
	// 	name: "Single RateSheet",
	// 	icon: "fal fa-users",
	// 	component: SingleRateSheetScreen,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/reporting-data",
	// 	name: "User",
	// 	icon: "fal fa-user",
	// 	component: ReportingData,
	// 	layout: "/",
	// },
	// {
	// 	noShow: true,
	// 	path: "/projects",
	// 	name: "Projects",
	// 	icon: "fal fa-user",
	// 	component: AdminProjects,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/equipment",
	// 	name: "Equipment",
	// 	component: AdminEquipment,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/equipment/:id",
	// 	name: "Single Equipment",
	// 	component: AdminSingleEquipment,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/emails",
	// 	name: "Emails",
	// 	component: Emails,
	// 	layout: "/admin",
	// },
	// {
	// 	noShow: true,
	// 	path: "/emails/:id",
	// 	name: "Single Email",
	// 	component: Email,
	// 	layout: "/admin",
	// },
	//
	// // Clerk Views
	//
	// {
	// 	path: "/dashboard",
	// 	name: "Dashboard",
	// 	component: ClerkDashboard,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/users",
	// 	name: "Users",
	// 	icon: "fal fa-users",
	// 	component: Users,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/locations",
	// 	name: "Location",
	// 	icon: "fal fa-users",
	// 	component: ClerkLocations,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/project-roles",
	// 	name: "Project Roles",
	// 	icon: "fal fa-users",
	// 	component: ProjectRolesScreen,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/subcontractors",
	// 	name: "Subcontractors",
	// 	icon: "fal fa-users",
	// 	component: ClerkSubcontractorScreen,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/expense-types",
	// 	name: "Expense Types",
	// 	icon: "receipt",
	// 	component: ExpenseTypes,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/list-users",
	// 	name: "List Users",
	// 	icon: "fal fa-users",
	// 	component: ListUsers,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/subcontractors/:id",
	// 	name: "Single Subcontractor",
	// 	icon: "fal fa-users",
	// 	component: SingleSubcontractorScreen,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/billing-rates",
	// 	name: "Billing Rate Sheets",
	// 	icon: "file-invoice-dollar",
	// 	component: BillingRateSheets,
	// 	layout: "/clerk",
	// },
	// {
	// 	path: "/billing-rates/:id",
	// 	name: "Single Rate Sheets",
	// 	icon: "file-invoice-dollar",
	// 	component: SingleRateSheet,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/projects",
	// 	name: "Projects",
	// 	icon: "fal fa-user",
	// 	component: ClerkWorkInProgress,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/projects/:id",
	// 	name: "Project",
	// 	component: ClerkProject,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/projects/:id/:billingPeriodID",
	// 	name: "Project Billing Period",
	// 	component: ClerkProject,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/clients",
	// 	icon: "fal fa-user",
	// 	component: ClerkClients,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/clients/:id",
	// 	icon: "fal fa-user",
	// 	component: ClerkClient,
	// 	layout: "/clerk",
	// },
	// {
	// 	noShow: true,
	// 	path: "/users/:id",
	// 	icon: "fal fa-user",
	// 	component: ClerkUser,
	// 	layout: "/clerk",
	// },
	//
	// // Time Clock
	//
	// {
	// 	noShow: true,
	// 	path: "/time-clock",
	// 	name: "Time Clock",
	// 	component: TimeClock,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/time-clock/:date",
	// 	name: "Time Clock Single Day",
	// 	component: SingleDayTimeClock,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/time-clock/:date/single-time-entry/:id",
	// 	name: "Time Clock Single Entry",
	// 	component: SingleDayTimeEntry,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/manager-center",
	// 	name: "Manager Center",
	// 	component: ManagerCenter,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/manager-center/:id",
	// 	name: "Employee",
	// 	component: Employee,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/time-analysis",
	// 	name: "Payroll Center",
	// 	component: TimeAnalysis,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// },
	// {
	// 	noShow: true,
	// 	path: "/time-analysis/:id",
	// 	name: "Payroll Center",
	// 	component: UserTime,
	// 	isMenuItem: true,
	// 	layout: "/app",
	// },
];

export default routes;
