import React from "react";
import { useHistory, useParams } from "react-router-dom";

// gql

import { gql, useQuery } from "@apollo/client";
import * as userGQL from "../../graphql/user";

// util

import { API_URL } from "../../util";

export const ContactCard = () => {
	const { id } = useParams();
	const history = useHistory();
	const [user, setUser] = React.useState(null);

	useQuery(gql(userGQL.getUser), {
		variables: { id },
		onCompleted: (data) => {
			if (data.getUser.isTeamMember) {
				setUser(data.getUser);
			} else {
				history.goBack();
			}
		},
		onError: () => {
			history.goBack();
		},
	});

	const createContactCard = () => {
		fetch(`${API_URL}/contact/contact-card`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ user }),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.url) {
					window.open(data.url);
				} else {
					alert(
						"Sorry, there was an error creating this contact card. Please try again."
					);
				}
			})
			.catch(() => {
				alert(
					"Sorry, there was an error creating this contact card. Please try again."
				);
			});
	};

	if (!user) return null;

	return (
		<div className="flex items-center justify-center">
			<div className="  w-full md:w-1/2 lg:max-w-2/5 lg:w-2/5">
				<div className="w-full">
					{/* User info and profile picture */}
					<div className=" flex-col">
						<div className=" px-4">
							<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ">
								<div className="px-6">
									<div className="flex flex-wrap items-end">
										<div className="w-full">
											<div className="py-6 mt-32 sm:mt-0 align-end">
												<button
													onClick={() => createContactCard()}
													className="bg-white active:bg-gray-100 text-gray-900 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 shadow-sm"
													type="button"
												>
													Save Connect Card
												</button>
											</div>
										</div>
									</div>

									<div className="text-center mt-12 flex flex-col items-center justify-center">
										<div className="">
											{user?.profilePicture ? (
												<img
													alt="..."
													src={user?.profilePicture}
													className="shadow-xl rounded-full align-middle border-none w-32 h-32 "
												/>
											) : (
												<div className="bg-indigo-500 shadow-xl rounded-full  align-middle border-none h-50 w-32 h-32 text-white text-4xl flex items-center justify-center ">
													{user?.firstName[0]}
													{user?.lastName[0]}
												</div>
											)}
										</div>
										<h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-700 ">
											{user?.firstName} {user?.lastName}
										</h3>

										<div className="mb-2 text-gray-600 ">
											<i className="fas fa-briefcase mr-2 text-lg text-gray-400"></i>
											{user?.role}
										</div>
										{user?.location && (
											<div className="mb-2 text-gray-600 font-medium ">
												<i className="fas fa-building mr-2 text-lg text-gray-400"></i>
												{user.location.name}
											</div>
										)}
									</div>
									{user.bio && (
										<div className="mt-10 py-10 border-t border-gray-200 text-center">
											<div className="flex flex-wrap justify-center">
												<div className="w-full lg:w-9/12 px-4">
													<p className="mb-4 text-lg leading-relaxed text-gray-700">
														user.bio
													</p>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="flex-1 flex flex-wrap justify-center mt-10 py-10 border-t border-gray-200 text-center">
									<div className="w-full px-4">
										<div className="flex flex-wrap space-y-2">
											<a
												href={`tel:${user?.phone}`}
												className=" w-full inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-gray-900 shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-center border-gray-300"
											>
												<i className="fad fa-phone mr-4"></i>
												<p>Call {user.firstName}</p>
											</a>

											{/* email */}
											<a
												href={`mailto:${user?.email}`}
												className=" w-full inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-gray-900 shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-center border-gray-300"
											>
												<i className="fad fa-envelope mr-4"></i>
												<p>Email {user.firstName}</p>
											</a>

											<a
												href={"tel:$318-222-2364"}
												className=" w-full inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-gray-900 shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-center border-gray-300"
											>
												<i className="fad fa-phone mr-4"></i>
												<p>Call the Corporate Office</p>
											</a>
											<a
												href={"https://srpenvironmental.com"}
												relation="noopener noreferrer"
												target="_blank"
												className=" w-full inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-gray-900 shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-center border-gray-300"
												rel="noreferrer"
											>
												<i className="fad fa-browser mr-4"></i>
												<p>Visit our Website</p>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
