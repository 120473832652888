/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { ExclamationIcon } from "@heroicons/react/solid";

export default function Alert({ message, variant }) {
	return (
		<div className={`bg-${variant}-50 border-l-4 p-4 border-${variant}-400`}>
			<div className="flex">
				<div className="flex-shrink-0">
					<ExclamationIcon
						className={`h-5 w-5 text-${variant}-400`}
						aria-hidden="true"
					/>
				</div>
				<div className="ml-3">
					<p className={`text-sm text-${variant}-700`}>{message}</p>
				</div>
			</div>
		</div>
	);
}

Alert.propTypes = {
	message: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired,
};
