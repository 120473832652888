import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import Blog from "components/Blog";
import { Link } from "react-router-dom";
import * as prismic from "@prismicio/client";

import { PublicFooter } from "components/Footer";
import ThreeRender from "components/ThreeRender";
const apiEndpoint = "https://srpinfield.cdn.prismic.io/api/v2";
const Client = prismic.createClient(apiEndpoint);

export default function LandingPage() {
	let [page, setPage] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			await Client.query(
				prismic.Predicates.at("document.type", "home_page")
			).then(function (response) {
				var doc = response.results[0];

				// doc contains the document content

				setPage(doc);
			});
		};
		fetchData();
	}, []);
	return (
		<main>
			{/* Hero section */}
			<div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-32">
				<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-2">
					<div>
						<div className="flex items-center">
							<img
								className="h-20 w-auto"
								src={require("../../assets/srp-icon.svg").default}
								alt="SRP inField"
							/>
							<h1 className="ml-2 text-6xl font-black text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600">
								SRP inField
							</h1>
						</div>
						<div className="mt-20">
							<div>
								<Link
									to={`/version/${page && page["uid"]}`}
									className="inline-flex space-x-4"
								>
									<span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-500 tracking-wide uppercase">
										What's new
									</span>
									<span className="inline-flex items-center text-sm font-medium text-indigo-500 space-x-1">
										<span>{page && page.data.version_number}</span>
										<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
									</span>
								</Link>
							</div>
							<div className="mt-6 sm:max-w-xl">
								<h1 className="text-4xl font-extrabold text-gray-900 dark:text-white tracking-tight sm:text-5xl">
									{page && page.data.tag_line[0].text}
								</h1>
								<p className="mt-6 text-xl text-gray-500">
									{page && page.data.description[0].text}
								</p>
							</div>
							<div className="flex mt-6">
								<a
									href="#learn-more"
									className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base text-center font-medium text-white shadow hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10"
								>
									Learn More
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="sm:mx-auto sm:max-w-3xl sm:px-6" id="learn-more">
					<div className="p-12 relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
						{/* Three.js canvas */}
						<div className="h-4/6">
							<ThreeRender />
						</div>

						<p className="mt-6 text-xl text-gray-500 mr-2">
							SRP inField is using{" "}
							<span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600">
								3D technology
							</span>{" "}
							to create a more immersive experience for in field personnel,
							clients, and contractors when access damaged spaces
						</p>
					</div>
				</div>
			</div>

			{/* Testimonial/stats section */}
			<div className="relative mt-20">
				<div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
					<div className="relative sm:py-16 lg:py-0">
						<div
							aria-hidden="true"
							className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
						>
							<div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 dark:bg-gray-800 rounded-r-3xl lg:right-72" />
							<svg
								className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
								width={404}
								height={392}
								fill="none"
								viewBox="0 0 404 392"
							>
								<defs>
									<pattern
										id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
										x={0}
										y={0}
										width={20}
										height={20}
										patternUnits="userSpaceOnUse"
									>
										<rect
											x={0}
											y={0}
											width={4}
											height={4}
											className="text-gray-200"
											fill="currentColor"
										/>
									</pattern>
								</defs>
								<rect
									width={404}
									height={392}
									fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
								/>
							</svg>
						</div>
						<div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
							{/* Testimonial card*/}
							<div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
								<img
									className="absolute inset-0 h-full w-full object-cover"
									src={require("../../assets/workers-ipad-min.jpg").default}
									alt=""
								/>
								<div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
								<div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" />
								<div className="relative px-8">
									<blockquote className="mt-8">
										<div className="relative text-lg font-medium text-white md:flex-grow">
											<p className="relative">
												SRP inField allows our field staff to assess projects
												faster, gain better KPI's and lets our office staff work
												in real time to deliver the client faster results
											</p>
										</div>
									</blockquote>
								</div>
							</div>
						</div>
					</div>

					<div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
						{/* Content area */}
						<div className="pt-12 sm:pt-16 lg:pt-20">
							<h2 className="text-3xl text-gray-900 dark:text-white font-extrabold tracking-tight sm:text-4xl">
								On a mission to change Environmental and IH
							</h2>
							<div className="mt-6 text-gray-500 space-y-6">
								<p className="text-lg">
									SRP in field is on a mission to change the environmental and
									industrial hygiene industry. With our platform we are aiming
									to provide better, more accurate details and the faster
									project turn round times to our clients and their vendors
								</p>
								<p className="text-base leading-7">
									This platform has been years in the making and crafted after
									our team's resilient process but leaving the redundancies
									behind which leads to less errors and better results
								</p>
							</div>
						</div>

						{/* Stats section */}
						{/* <div className="mt-10">
							<dl className="grid grid-cols-2 gap-x-4 gap-y-8">
								{stats.map((stat) => (
									<div
										key={stat.label}
										className="border-t-2 border-gray-100 pt-6"
									>
										<dt className="text-base font-medium text-gray-500">
											{stat.label}
										</dt>
										<dd className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
											{stat.value}
										</dd>
									</div>
								))}
							</dl>
							
						</div> */}
					</div>
				</div>
			</div>

			<Blog />

			<PublicFooter />
		</main>
	);
}
