/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function BlogContainer({ title, description, children }) {
	return (
		<div className="tbg-white dark:bg-gray-900 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
			<div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
				<div>
					<h2 className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-4xl">
						{title}
					</h2>
					<p className="mt-3 text-xl text-gray-500 sm:mt-4">{description}</p>
				</div>
				<div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
					{children}
				</div>
			</div>
		</div>
	);
}

BlogContainer.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export const Blog = ({ post }) => {
	return (
		<div key={post.title}>
			<div>
				<p className="inline-block">
					<span
						className={classNames(
							"inline-flex items-center px-3 rounded-full text-sm font-medium capitalize"
						)}
					>
						{post.type}
					</span>
				</p>
			</div>
			<Link to={post.href} className="block mt-4">
				<p className="text-xl font-semibold text-gray-900 dark:text-white">
					{post.title}
				</p>
				<p className="mt-3 text-base text-gray-500">{post.description}</p>
			</Link>
			<div className="mt-6 flex items-center">
				<div className="flex-shrink-0">
					{/* <a href={post.author.href}>
            <span className="sr-only">{post.author.name}</span>
          </a> */}
				</div>
				<div className="ml-3">
					{/* <p className="text-sm font-medium text-gray-900 dark:text-white">
            <a href={post.author.href}>{post.author.name}</a>
          </p> */}
					<div className="flex space-x-1 text-sm text-gray-500">
						<time dateTime={post.date}>{post.date}</time>
						<span aria-hidden="true">&middot;</span>
					</div>
				</div>
			</div>
		</div>
	);
};

Blog.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	}),
};
