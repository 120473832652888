import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import { addNotification } from "../../components/Notifications";
import { LockClosedIcon } from "@heroicons/react/solid";
import Form, { Input } from "components/Forms";
import Alert from "components/Alert";

// GQL

import { gql, useQuery, useMutation } from "@apollo/client";
import * as queries from "../../graphql/user";
import { DateTime } from "luxon";

// Utils

import { API_URL } from "../../util";

const FinishRegistration = ({ history, onUserInfoCollected }) => {
	let [error, setShowError] = React.useState(null);
	let [linkActive, setLinkActive] = React.useState(true);
	let [user, setUser] = React.useState(null);
	let [view, setView] = React.useState("password");
	let [password, setPassword] = React.useState("");
	let [code, setCode] = React.useState("");

	let { id } = useParams();

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	useQuery(gql(queries.getUser), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			if (data.getUser.isActive === false) {
				addNotification({
					title: "Inactive Account",
					text: "Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake",
					type: "error",
					supportText: true,
					time: 5000,
				});
				return history.push("/");
			} else {
				// check to see if link has expired

				if (data.getUser.linkExpiration < DateTime.now().toISO()) {
					setLinkActive(false);
				} else {
					setUser(data.getUser);
				}
			}
		},
		onError: () => {
			addNotification({
				title: "Inactive Account",
				text: "Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake",
				type: "error",
				supportText: true,
				time: 5000,
			});
		},
	});

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			onUserInfoCollected(data.resetPassword);
			setView("login");
			return history.push("/app");
		},
		onError: (error) => {
			setShowError(error.message);
		},
	});

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.then(() => {
				setView("code");
			})
			.catch(() => {
				Alert(
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password ",
					"error"
				);
			});
	};

	if (!user && linkActive) {
		return <LoadingScreen loadItem={"Information"} />;
	}

	if (!linkActive) {
		return (
			<>
				<h3 className="text-center mb-40">Hello {user.firstName}</h3>
				<p className="text-center">
					Unfortunately the link is no longer active. Our links are set to
					expire 24 hours after they've been sent. Please reach out to support
					to receive another link.
				</p>
			</>
		);
	}

	const createCredentials = async () => {
		if (
			!(
				password.length >= 8 &&
				password.match(upperCaseLetters) &&
				password.match(lowerCaseLetters) &&
				password.match(numbers) &&
				password.match(special)
			)
		) {
			return alert("Your password does not meet the password requirements");
		}

		try {
			generatePasswordResetToken({
				variables: {
					email: user.email,
				},
			});
		} catch (error) {
			alert("We couldn't generate your verification token. Please try again");
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div>
					<img
						className="mx-auto h-12 w-auto"
						src={require("../../assets/srp-icon.svg").default}
						alt="SRP inField"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
						Welcome {user.firstName}!
					</h2>
				</div>

				{view === "password" ? (
					<>
						<p className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Welcome to SRP inField! You're almost ready to use your account.
							Please enter a password and we will send you a code to confirm
							your account Please note your email is{" "}
							<span className="font-bold">{user.email}</span>
						</p>
						{error && <Alert variant="red" message={error} />}
						<Form
							className="mt-8 space-y-6"
							onSubmit={(e) => {
								e.preventDefault();
								createCredentials();
							}}
						>
							<Input
								label="Password"
								placeholder="Password"
								type="password"
								id="password"
								required
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>

							<p className="text-xs">
								<span className={password.length >= 8 && "text-indigo-500"}>
									<i
										className={`fas ${
											password.length >= 8 ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									8 Characters{""}
								</span>{" "}
								<span
									className={
										password.match(upperCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fas ${
											password.match(upperCaseLetters) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Upper Case{" "}
								</span>{" "}
								<span
									className={
										password.match(lowerCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fas ${
											password.match(lowerCaseLetters) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Lower Case{" "}
								</span>{" "}
								<span className={password.match(special) && "text-indigo-500"}>
									<i
										className={`fas ${
											password.match(special) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Special Character{" "}
								</span>{" "}
								<span className={password.match(numbers) && "text-indigo-500"}>
									<i
										className={`fas ${
											password.match(numbers) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Number{" "}
								</span>
							</p>
							<div className="mt-6">
								<button
									type="submit"
									className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									<span className="absolute left-0 inset-y-0 flex items-center pl-3">
										<LockClosedIcon
											className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Set Password
								</button>
							</div>
						</Form>
					</>
				) : (
					<>
						<p className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your <strong>the code</strong> that was just sent to your
							email.
						</p>
						{error && <Alert variant="red" message={error} />}
						<Form
							className="mt-8 space-y-6"
							onSubmit={(e) => {
								e.preventDefault();
								resetPassword({
									variables: {
										email: user.email,
										password: password,
										code: code,
									},
								});
							}}
						>
							<Input
								label="Code"
								placeholder="Auth Code"
								required
								type="text"
								value={code}
								onChange={(e) => {
									setCode(e.target.value);
								}}
							/>

							<p
								onClick={() => {
									generatePasswordResetToken({
										variables: {
											email: user.email,
										},
									});
								}}
								className="mt-2 text-indigo-500 group-hover:text-indigo-400 dark:text-gray-100 dark:group-hover:text-indigo-50 cursor:pointer"
							>
								Resend Authentication Code
							</p>

							<div className="mt-6">
								<button
									type="submit"
									className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									<span className="absolute left-0 inset-y-0 flex items-center pl-3">
										<LockClosedIcon
											className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Verify and Sign In
								</button>
							</div>
						</Form>
					</>
				)}
			</div>
		</div>
	);
};

FinishRegistration.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	onUserInfoCollected: PropTypes.func,
};

export default FinishRegistration;
