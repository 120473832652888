// GraphQL

import { ApolloProvider } from "@apollo/client";

// Others

import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Router, Switch, useHistory } from "react-router-dom";

import PublicLayout from "layouts/Public.js";
import SupportLayout from "layouts/Support.js";

import NotificationContainer from "components/Notifications";
import { CookiesProvider } from "react-cookie";

//toast

//import { ToastContainer, toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

import { useApolloClient } from "hooks/useApolloClent";
import "index.css";
import Navbar from "./components/Navbar";
const hist = createBrowserHistory();

export default function MoveToNewApp() {
	return (
		<>
			<Navbar />
			<main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
				<div className="text-center">
					<p className="text-base font-semibold text-indigo-600">403</p>
					<h2 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">SRP inField Has Moved</h2>
					<p className="mt-6 text-base leading-7 text-gray-600">We are excited to inform you that SRP inField has moved to a new domain! You can now access our app at app.srpinfield.com.</p>

					<div className="text-left mx-auto max-w-4xl">
					<div className="mt-2 ">
						<h3 className={"text-2xl font-bold tracking-tight text-indigo-500"}>Why the Change?</h3>
						<p>To enhance your experience and streamline our services, we've transitioned our app to a dedicated domain, <a className="text-indigo-500"  href={"https://app.srpinfield.com"}>app.srpinfield.com</a>. This move allows us to improve performance, security, and provide you with a more focused and efficient user experience.</p>


					</div>


					<div className="mt-2">
						<h3 className={"text-indigo-500 text-2xl font-bold tracking-tight"}>What Do You Need to Do?</h3>
						<ol className="space-y-2">
							<li><b>Update Your Bookmarks</b>: If you have bookmarked srpinfield.com in your browser, please update it to app.example.com to access the app seamlessly.</li>
							<li><b>Update Your Mobile App</b>: If you've downloaded our mobile app, ensure it's up to date by visiting the App Store or Google Play Store. We regularly release updates through OTA (over the air) updates, enabling us to provide you with new features and improvements promptly. Be sure to periodically close and reopen the app to receive the latest updates and maintain the most current version from the App Store or Google Play Store. </li>
							<li><b>Login as Usual</b>: When visiting <a  className="text-indigo-500" href="https://app.srpinfield.com">app.srpinfield.com</a>, use your existing login credentials to access your account just like before.</li>
						</ol>
					</div>

					</div>

					<div className="mt-10 flex items-center justify-center gap-x-6">
						<a
							href="https://app.srpinfield.com"
							className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Go to new app
						</a>

					</div>
				</div>
			</main>
		</>
	);
}


const AppViews = () => {
	const history = useHistory();
	const authToken = JSON.parse(localStorage.getItem("user"))?.token;
	const client = useApolloClient({
		authToken,
		removeUser: () => {
			localStorage.removeItem("user");
			window.location.href = "/";

			// toast(
			// 	<div>
			// 		Session Expired
			// 		<br /> <p className="font-light">Please Sign In Again</p>
			// 	</div>,
			// 	{
			// 		type: "error",
			// 	}
			// );
		},
	});

	React.useEffect(() => {
		// Check if the user is logged in or not

		const getLocalUserInformation = async () => {
			let user = JSON.parse(localStorage.getItem("user"));

			if (user && !user.token) {
				// send to index page

				localStorage.removeItem("user");

				// toast(
				// 	<div>
				// 		Session Expired
				// 		<br /> <p className="font-light">Please Sign In Again</p>
				// 	</div>,
				// 	{
				// 		type: "error",
				// 	}
				// );

				history.push("/");
			}
		};

		getLocalUserInformation();
	}, []);

	if (!client) return;

	return (
		<ApolloProvider client={client}>
			<Switch>
				<Route path="/app" render={() => <MoveToNewApp />} />
				<Route path="/admin" render={() => <MoveToNewApp />} />
				<Route
					path="/subAdmin"
					render={() => <MoveToNewApp />}
				/>
				<Route path="/clerk" render={() => <MoveToNewApp />} />
				<Route path="/help" render={(props) => <SupportLayout {...props} />} />
				<Route path="/" render={(props) => <PublicLayout {...props} />} />
			</Switch>
		</ApolloProvider>
	);
};

const App = () => {
	return (
		<div className="body">
			<CookiesProvider>
				<Router history={hist}>
					<AppViews />
					<NotificationContainer />
				</Router>
				{/* <ToastContainer /> */}
			</CookiesProvider>
		</div>
	);
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
