export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			payrollExempt
			isTimeAdmin
			isTimeOverrider
			location {
				id
				name
			}
			apsEmployeeID
		}
	}
`;

export const updateUser = /* GraphQL */ `
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			subAdmin
			isEquipmentManager
			isLogistic
			isActive
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			payrollExempt
			isTimeAdmin
			isTimeOverrider
			location {
				id
				name
			}
			apsEmployeeID
		}
	}
`;

export const getProfileInfo = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			payrollExempt
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isTeamMember
			isContractor
			isLogistic
			isActive
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			subcontractor {
				id
				name
			}
			dailyDocuments {
				id
				date
				reportGeneration
				timeIn
				timeOut
				project {
					id
					title
				}
			}
		}
	}
`;

export const getUserandProjects = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			payrollExempt
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isTeamMember
			isContractor
			isLogistic
			isActive
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			isManager
			manager {
				id
			}
			projects {
				id
				project {
					id
					title
					stage
					workspace {
						id
						title
					}
				}
				isActive
			}
		}
	}
`;
export const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: TableUserFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				firstName
				lastName
				profilePicture
				email
				phone
				bio
				role
				isAdmin
				isClerk
				isTeamMember
				isContractor
				isPayrollAdmin
				payrollExempt
				isTimeOverrider
				isTimeAdmin
				isTimeOverrider
				isEquipmentManager
				isLogistic
				isActive
				subAdmin
				linkExpiration
				canReviewReports
			}
			nextToken
		}
	}
`;

export const generatePasswordResetToken = /* GraphQL */ `
	mutation GeneratePasswordResetToken($email: String!) {
		generatePasswordResetToken(email: $email) {
			id
			email
			passwordResetToken
		}
	}
`;

export const resetPassword = /* GraphQL */ `
	mutation ResetPassword($email: String!, $code: String!, $password: String!) {
		resetPassword(email: $email, code: $code, password: $password) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			token
		}
	}
`;

export const login = /* GraphQL */ `
	query Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			token
		}
	}
`;

export const checkActiveStatus = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			isActive
		}
	}
`;

export const createUser = /* GraphQL */ `
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			firstName
			lastName
			email
			phone
		}
	}
`;
