import React from "react";
import PropTypes from "prop-types";

export default function LoadingScreen({ loadItem, subtext }) {
	return (
		<>
			<div className="bg-white dark:bg-gray-800 h-full flex flex-col justify-center">
				<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
					<div className="text-center">
						<h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
							SRP inField
						</h2>
						<div className="flex flex-row">
							<p className="mt-1 text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
								Loading {loadItem}
							</p>
							<span className="flex items-center justify-center h-3 w-3">
								<span className="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-purple-400 opacity-75"></span>
								<span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
							</span>
						</div>
						{subtext && (
							<p className="mt-1 text-xl font-medium text-indigo-500">
								{subtext}
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

LoadingScreen.propTypes = {
	loadItem: PropTypes.string.isRequired,
	subtext: PropTypes.string,
};
