import AWS from "aws-sdk";
import { DateTime } from "luxon";

export const projectRoles = [
	{
		name: "Project Director",
		value: "Project Director",
	},
	{
		name: "Senior Project Manager",
		value: "Senior Project Manager",
	},
	{
		name: "Project Manager",
		value: "Project Manager",
	},
	{
		name: "Assistant Project",
		value: "Assistant Project",
	},
	{
		name: "Certified Industrial Hygienist",
		value: "Certified Industrial Hygienist",
	},
	{
		name: "Senior Industrial Hygienist",
		value: "Senior Industrial Hygienist",
	},
	{
		name: "Industrial Hygienist",
		value: "Industrial Hygienist",
	},
	{
		name: "Industrial Hygiene Tech",
		value: "Industrial Hygiene Tech",
	},
	{
		name: "Data Project Manager",
		value: "Data Project Manager",
	},
	{ name: "Data Analyst", value: "Data Analyst" },
	{ name: "Senior Admin", value: "Senior Admin" },
	{ name: "Admin", value: "Admin" },
	{ name: "Clerk", value: "Clerk" },
];

export const projectStages = [
	{
		name: "Mobilization",
		value: "Mobilization",
		color: "purple",
	},
	{
		name: "Initial Assessment",
		value: "Initial Assessment",
		color: "red",
	},
	{
		name: "Clearance",
		value: "Clearance",
		color: "yellow",
	},
	{
		name: "Demobilization",
		value: "Demobilization",
		color: "blue",
	},
	{
		name: "Billing",
		value: "Billing",
		color: "pink",
	},
	{
		name: "Complete",
		value: "Complete",
		color: "green",
	},
];

export const sampleTypes = [
	{
		name: "Microbiology",
		value: "MICROBIOLOGY",
		color: "pink",
	},
];

export const EquipmentModals = [
	{
		name: "iPhone",
		value: "iPhone",
	},
	{
		name: "iPad Pro",
		value: "iPad Pro",
	},
	{
		name: "MacBook Pro",
		value: "MacBook Pro",
	},
	{
		name: "PC",
		value: "PC",
	},
	{
		name: "FlIR",
		value: "FlIR",
	},
	{
		name: "Protimeter",
		value: "Protimeter",
	},
	{
		name: "Tramex",
		value: "Tramex",
	},
	{
		name: "Ricoh Theta",
		value: "Ricoh Theta",
	},
	{
		name: "Other",
		value: "Other",
	},
];

/**
 * handleTimeEntryChange
 * handles the change of the time entry form and converts the time to an ISO string
 * @param {string} timeEntry - the target from the time entry input
 * @returns string - the ISO formatted date
 */

export const handleTimeEntryChange = (timeEntry) => {
	// get year, date, month, hour, minute, and second

	let date = new Date(timeEntry);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	return DateTime.local(year, month, day, hour, minute).toISO();
};

/**
 * calculateTimeDuration
 * @param {string} timeIn - ISO 8601 String
 * @param {string} timeOut - ISO 8601 String
 * @returns {string} - formatted time difference
 */

export const calculateTimeDuration = (timeIn, timeOut) => {
	return DateTime.fromISO(timeOut, { setZone: true })
		.diff(DateTime.fromISO(timeIn, { setZone: true }))
		.toFormat("hh:mm:ss");
};

const getAPIurl = () => {
	let url = "";

	if (process.env.REACT_APP_STAGE === "dev") {
		url = "http://localhost:3002/local";
	} else if (process.env.REACT_APP_STAGE === "staging") {
		url = "https://0gy61a1xgf.execute-api.us-west-2.amazonaws.com/staging";
	} else if (process.env.REACT_APP_STAGE === "clerking-test") {
		url =
			"https://1qjdksojug.execute-api.us-west-2.amazonaws.com/clerking-test";
	} else {
		url = "https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2";
	}
	return url;
};

export const API_URL = getAPIurl();

AWS.config.update({
	region: "us-east-1",
	accessKeyId: "AKIATO7GJDKQMIMBUMNA",
	secretAccessKey: "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",
});

export const checkForDailySheetAvailability = (project, user) => {
	let sheets = project.dailyDocuments;
	let canAddNewSheet = true;
	sheets.map((sheet) => {
		if (
			sheet.user.id === user.id &&
			DateTime.fromISO(sheet.date).toFormat("yyyy-LL-dd") ===
				DateTime.now().toFormat("yyyy-LL-dd")
		) {
			canAddNewSheet = false;
		}
	});

	return canAddNewSheet;
};

export const s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const sendUpdateEmail = async (
	project,
	user,
	reason,
	approvedBy,
	previousTimeIn,
	previousTimeOut,
	updatedTimeIn,
	updatedTimeOut
) => {
	let input = {
		project: project,
		user: user,
		reason: reason,
		approvedBy: approvedBy,
		previousTimeIn: previousTimeIn,
		previousTimeOut: previousTimeOut,
		updatedTimeIn: updatedTimeIn,
		updatedTimeOut: updatedTimeOut,
	};

	// await fetch(`${API_URL}/emails/updated-time`, {
	// 	method: "POST",
	// 	body: JSON.stringify(input),
	// 	headers: {
	// 		"Content-Type": "application/json",
	// 	},
	// })
	// 	.then((data) => {
	//
	// 		history.push("/TimeEntrySlideOver");
	// 	})
	// 	.catch((err) => {
	//
	// 	});

	return input;
};

export const getSignedUrl = (link) => {
	let params = { Bucket: "srp-in-field", Key: link };
	return s3.getSignedUrl("getObject", params);
};
