import * as userGQL from "../../graphql/user";
import React from "react";
import Form, { Checkbox, Input } from "../../components/Forms";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
const Register = () => {
	let [userRequest, setUserRequest] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		organization: "",
		isRequesting: true,
	});

	const [accepted, setAccepted] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [createUserRequest] = useMutation(gql(userGQL.createUser), {
		onCompleted: () => {
			setSuccess(true);
		},
		onError: () => {
			alert("Couldn't create user please try again");
		},
	});

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div>
					<img
						className="mx-auto h-12 w-auto"
						src={require("../../assets/srp-icon.svg").default}
						alt="SRP inField"
					/>

					{success ? (
						<>
							<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
								Thank You
							</h2>
							<p className="mt-6 text-center font-extrabold text-gray-900 dark:text-white">
								We are reviewing your request and you should have a response
								within 48 hours
							</p>
						</>
					) : (
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
							Request An Account
						</h2>
					)}
				</div>

				{!success && (
					<Form
						className="mt-8 space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
							createUserRequest({
								variables: {
									input: userRequest,
								},
							});
						}}
					>
						<div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
							<Input
								label="First Name"
								placeholder="John"
								className="col-span-3"
								value={userRequest.firstName}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										firstName: e.target.value,
									});
								}}
								required
							/>
							<Input
								label="Last Name"
								placeholder="Smith"
								className="col-span-3"
								value={userRequest.lastName}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										lastName: e.target.value,
									});
								}}
								required
							/>
						</div>

						<div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
							<Input
								label="Email"
								placeholder="jsmith@srpenvironmental.net"
								className="col-span-6"
								value={userRequest.email}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										email: e.target.value,
									});
								}}
								required
							/>
							<Input
								label="Phone"
								placeholder="(XXX) XXX-XXXX"
								className="col-span-6"
								value={userRequest.phone}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										phone: e.target.value,
									});
								}}
								required
							/>
							<Input
								label="Organization"
								placeholder="Organization"
								className="col-span-6"
								value={userRequest.organization}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										organization: e.target.value,
									});
								}}
								required
							/>
						</div>
						<Checkbox
							className="mt-6"
							required
							checkedValue={accepted}
							onChange={() => {
								setAccepted(!accepted);
							}}
							description={
								<p className=" text-gray-500">
									By requesting an account you agree to SRP inField's{" "}
									<Link className={"text-indigo-500"} to="/tos">
										Terms and Conditions
									</Link>
								</p>
							}
						/>

						<div className="mt-6">
							<button
								type="submit"
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								<span className="absolute left-0 inset-y-0 flex items-center pl-3">
									<LockClosedIcon
										className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
										aria-hidden="true"
									/>
								</span>
								Request Account
							</button>
						</div>
					</Form>
				)}
			</div>
		</div>
	);
};

export default Register;
