import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { addNotification } from "../../components/Notifications";
import { LockClosedIcon } from "@heroicons/react/solid";
import Form, { Input } from "../../components/Forms";
import Alert from "components/Alert";

// GQL

import { gql, useMutation, useLazyQuery } from "@apollo/client";
import * as queries from "../../graphql/user";

// utils

import { API_URL } from "../../util";

const Login = ({ history, onUserInfoCollected }) => {
	let [username, setUsername] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [showError, setShowError] = React.useState("");
	let [view, setView] = React.useState("login");
	let [attemptingLogin, setAttemptingLogin] = React.useState(false);
	let [code, setCode] = React.useState("");
	let [newPassword, setNewPassword] = React.useState("");

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			onUserInfoCollected(data.resetPassword);
			setView("login");
			return history.push("/app");
		},
		onError: (error) => {
			setShowError(error.message);
		},
	});

	let [login] = useLazyQuery(gql(queries.login), {
		onCompleted: (data) => {
			if (data.login && data.login.isActive === false) {
				addNotification({
					title: "Inactive Account",
					text: "Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake",
					type: "error",
					supportText: true,
					time: 5000,
				});
			}
			if (data.login) {
				onUserInfoCollected(data.login);
				setAttemptingLogin(false);
				setView("login");

				return history.push("/app");
			} else {
				setAttemptingLogin(false);
				setShowError("Invalid username or password");
			}
		},
		onError: (error) => {
			if (error.message === "You must reset your password") {
				generatePasswordResetToken({ variables: { email: username } });
			} else {
				setShowError(error.message);
				setAttemptingLogin(false);
			}
		},
	});

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.then(() => {
				setView("reset");
			})
			.catch(() => {
				Alert(
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password ",
					"error"
				);
			});
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
			{view === "login" ? (
				<div className="max-w-md w-full space-y-8">
					<div>
						<img
							className="mx-auto h-12 w-auto"
							src={require("./../../assets/srp-icon.svg").default}
							alt="Workflow"
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
							Sign in to your account
						</h2>
					</div>
					{showError && <Alert variant="red" message={showError} />}

					<Form
						className="mt-8 space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
							if (attemptingLogin) return;
							login({
								variables: {
									email: username,
									password: password,
								},
							});
							setShowError(false);
							setAttemptingLogin(true);
						}}
					>
						<input type="hidden" name="remember" defaultValue="true" />
						<div className="rounded-md shadow-sm space-y-3">
							<Input
								label="Email Address"
								placeholder="Email Address"
								onChange={(e) => setUsername(e.target.value)}
							/>
							<Input
								label="Password"
								type="password"
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<div className="flex items-center justify-between mt-2">
							<div className="text-sm">
								<Link
									to="/forgot-password"
									className="font-medium text-indigo-600 hover:text-indigo-500"
								>
									Forgot your password?
								</Link>
							</div>
						</div>

						<div>
							<button
								disabled={attemptingLogin ? true : false}
								type="submit"
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								<span className="absolute left-0 inset-y-0 flex items-center pl-3">
									<LockClosedIcon
										className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
										aria-hidden="true"
									/>
								</span>
								Sign in
							</button>
						</div>
					</Form>
				</div>
			) : (
				<div className="max-w-md w-full space-y-8">
					<div>
						<img
							className="mx-auto h-12 w-auto"
							src={require("./../../assets/srp-icon.svg").default}
							alt="Workflow"
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600">
							Time to reset your password
						</h2>
						<p className="mt-6 text-gray-900 dark:text-white text-center">
							We just send a code to your email so you can reset your password.
							Please enter it below followed by a new password
						</p>
					</div>
					{showError && <Alert variant="red" message={showError} />}

					<Form
						className="mt-8 space-y-6"
						onSubmit={(e) => {
							e.preventDefault();

							// check to make sure password meets the requirements

							if (
								!newPassword.length >= 8 ||
								!newPassword.match(upperCaseLetters) ||
								!newPassword.match(lowerCaseLetters) ||
								!newPassword.match(numbers) ||
								!newPassword.match(special)
							) {
								setShowError(
									"Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character"
								);
								return;
							}

							resetPassword({
								variables: {
									email: username,
									password: newPassword,
									code: code,
								},
							});

							setShowError(false);
							setAttemptingLogin(true);
						}}
					>
						<input type="hidden" name="remember" defaultValue="true" />
						<div className="rounded-md shadow-sm space-y-3">
							<Input
								label="Verification Code"
								placeholder="######"
								onChange={(e) => setCode(e.target.value)}
								value={code}
							/>
							<Input
								label="Password"
								type="password"
								placeholder="Password"
								onChange={(e) => setNewPassword(e.target.value)}
								value={newPassword}
							/>

							<strong style={{ fontSize: 10, lineHeight: 0.4 }}>
								<span className={newPassword.length >= 8 && "text-indigo-500"}>
									<i
										className={`fad ${
											newPassword.length >= 8 ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									8 Characters{" "}
								</span>
								|{" "}
								<span
									className={
										newPassword.match(upperCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fad ${
											newPassword.match(upperCaseLetters)
												? "fa-check"
												: "fa-times"
										}`}
									></i>{" "}
									Upper Case{" "}
								</span>
								|{" "}
								<span
									className={
										newPassword.match(lowerCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fad ${
											newPassword.match(lowerCaseLetters)
												? "fa-check"
												: "fa-times"
										}`}
									></i>{" "}
									Lower Case{" "}
								</span>
								|{" "}
								<span
									className={newPassword.match(special) && "text-indigo-500"}
								>
									<i
										className={`fad ${
											newPassword.match(special) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Special Character{" "}
								</span>
								|{" "}
								<span
									className={newPassword.match(numbers) && "text-indigo-500"}
								>
									<i
										className={`fad ${
											newPassword.match(numbers) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Number{" "}
								</span>
							</strong>
						</div>

						<div>
							<button
								disabled={attemptingLogin ? true : false}
								type="submit"
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								<span className="absolute left-0 inset-y-0 flex items-center pl-3">
									<LockClosedIcon
										className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
										aria-hidden="true"
									/>
								</span>
								Sign in
							</button>
						</div>
					</Form>
				</div>
			)}
		</div>
	);
};

Login.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	onUserInfoCollected: PropTypes.func.isRequired,
};

export default Login;
