/* This example requires Tailwind CSS v2.0+ */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

export default function NotificationContainer() {
	let [notifications, setNotifications] = useState(
		JSON.parse(localStorage.getItem("notifications"))
	);

	useEffect(() => {
		function updateNotifications() {
			setNotifications(JSON.parse(localStorage.getItem("notifications")));
		}

		window.addEventListener("storage", updateNotifications);

		return () => {
			window.removeEventListener("storage", updateNotifications);
		};
	}, []);

	return (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className="fixed inset-0 flex flex-col items-start justify-items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start overflow-y-scroll"
			>
				{notifications &&
					notifications.map((notification, index) => {
						return (
							<Notification
								key={index}
								index={index}
								notification={notification}
							/>
						);
					})}
			</div>
		</>
	);
}

export const Notification = ({ index, notification }) => {
	const [show, setShow] = useState(true);

	// if (notification.time) {
	// 	useInterval(() => {
	// 		setShow(false);
	// 		removeFromNotifications(index);
	// 	}, notification.time);
	// }

	const removeFromNotifications = (index) => {
		let notifications = JSON.parse(localStorage.getItem("notifications"));
		notifications.splice(index, 1);
		localStorage.setItem("notifications", JSON.stringify(notifications));
	};

	return (
		<div className="w-full flex flex-col items-center space-y-4 sm:items-end">
			{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
			<Transition
				show={show}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"

				// onMouseOver={() => {
				// 	setPause(true);
				// }}

			>
				<div className="max-w-sm w-full bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-2">
					<div className="p-4">
						<div className="flex items-start">
							<div className="flex-shrink-0">
								<i
									className={`h-6 w-6 fas
									${notification.type === "success" && "text-green-400  fa-check-circle"} ${
										notification.type === "error" &&
										"text-red-400  fa-times-circle"
									} ${
										notification.type === "info" &&
										"text-indigo-400 fa-info-circle"
									}
									`}
									aria-hidden="true"
								></i>
							</div>
							<div className="ml-3 w-0 flex-1 pt-0.5">
								<p className="text-sm font-medium text-gray-900 dark:text-white">
									{notification.title}
								</p>
								<p className="mt-1 text-sm text-gray-500 dark:text-white">
									{notification.text}{" "}
								</p>

								{notification.supportText && (
									<p className="mt-1 text-sm text-gray-500 dark:text-white">
										<i className="mt-1">
											If the problem continues to occur chat with us using the
											help/chat button or look at our support articles in the{" "}
											<Link to="/help" className="text-indigo-500">
												help center
											</Link>
										</i>
									</p>
								)}
							</div>
							<div className="ml-4 flex-shrink-0 flex">
								<button
									className="bg-white dark:bg-gray-900 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									onClick={() => {
										setShow(false);
										removeFromNotifications(index);
									}}
								>
									<span className="sr-only">Close</span>
									<XIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	);
};

Notification.propTypes = {
	index: PropTypes.number.isRequired,
	notification: PropTypes.shape({
		type: PropTypes.oneOf(["success", "error", "info"]),
		title: PropTypes.string.isRequired,
		text: PropTypes.string,
		supportText: PropTypes.bool,
	}),
};

export function addNotification(notification) {
	let notifications = JSON.parse(localStorage.getItem("notifications"));
	if (!notifications) {
		notifications = [];
	}
	notifications.push(notification);
	localStorage.setItem("notifications", JSON.stringify(notifications));
	window.dispatchEvent(new Event("storage"));
}
