import React, { useEffect } from "react";
import * as prismic from "@prismicio/client";
import { addNotification } from "components/Notifications";
import { useParams } from "react-router-dom";
import BlogContainer, { Blog } from "components/Blog";
const apiEndpoint = "https://srpinfield.cdn.prismic.io/api/v2";

const Client = prismic.createClient(apiEndpoint);
const SupportCategory = () => {
	let { id } = useParams();
	let [category, setCategory] = React.useState(null);
	let [documents, setDocuments] = React.useState(null);

	useEffect(() => {
		const fetchDocuments = async () => {
			await Client.query([
				prismic.Predicates.at("document.type", "support"),
				prismic.Predicates.at("my.support.category", id),
			])
				.then(function (response) {
					var doc = response;

					// doc contains the document content

					setDocuments(doc.results);
				})
				.catch(() => {
					addNotification({
						title: "Support Category Does Not Exist",
						text: "The support category you have requested does not exist",
						type: "error",
						time: 5000,
					});
				});
		};
		fetchDocuments();

		const getCategory = async () => {
			await Client.getByID(id)
				.then(function (response) {
					var doc = response;

					// doc contains the document content

					setCategory(doc);
				})
				.catch(() => {
					addNotification({
						title: "Support Category Does Not Exist",
						text: "The support category you have requested does not exist",
						type: "error",
						time: 5000,
					});
				});
		};
		getCategory();
	}, [id]);

	return (
		<BlogContainer
			title={category && category.data.name[0].text}
			description={category && category.data.description[0].text}
		>
			{documents &&
				documents.map((document, index) => {
					let post = {
						title: document.data.title[0].text,
						time: document.last_publication_date,
						type: document.type,
						href: `/help/support-article/${document.uid}`,
					};
					return <Blog post={post} key={index} />;
				})}
		</BlogContainer>
	);
};

export default SupportCategory;
