import React from "react";
import PropTypes from "prop-types";
import { LockClosedIcon } from "@heroicons/react/solid";
import Form, { Input } from "../../components/Forms";
import Alert from "../../components/Alert";

// GQL

import { gql, useMutation } from "@apollo/client";
import * as queries from "../../graphql/user";

// utils

import { API_URL } from "../../util";

const ForgotPassword = ({ history, onUserInfoCollected }) => {
	let [view, setView] = React.useState("email");
	let [email, setEmail] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [code, setCode] = React.useState("");
	let [error, setError] = React.useState(false);

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
			onError: (error) => {
				setError(error.message);
			},
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			onUserInfoCollected(data.resetPassword);
			setView("login");
			return history.push("/app");
		},
		onError: (error) => {
			setError(error.message);
		},
	});

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.then(() => {
				setView("reset");
			})
			.catch(() => {
				Alert(
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password ",
					"error"
				);
			});
	};

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div>
					<img
						className="mx-auto h-12 w-auto"
						src={require("../../assets/srp-icon.svg").default}
						alt="SRP inField"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
						Forgot Your Password?
					</h2>
				</div>

				{view === "email" ? (
					<>
						<p className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your email below. If your account exists we will send a
							verification code that you can enter to reset your password.
						</p>
						{error && <Alert variant="red" message={error} />}
						<Form
							className="mt-8 space-y-6"
							onSubmit={(e) => {
								e.preventDefault();
								generatePasswordResetToken({
									variables: {
										email,
									},
								});
							}}
						>
							<Input
								label="Email"
								placeholder="user@example.com"
								required
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>

							<div className="mt-6">
								<button
									type="submit"
									className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									<span className="absolute left-0 inset-y-0 flex items-center pl-3">
										<LockClosedIcon
											className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Get Verification Code
								</button>
							</div>
						</Form>
					</>
				) : (
					<>
						<p className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your <strong>the code</strong> that was just sent to your
							email and the new password. If you account exist we will reset
							your password and log you in.
						</p>
						{error && <Alert variant="red" message={error} />}
						<Form
							className="mt-8 space-y-6"
							onSubmit={(e) => {
								e.preventDefault();
								if (
									!password.length >= 8 ||
									!password.match(upperCaseLetters) ||
									!password.match(lowerCaseLetters) ||
									!password.match(numbers) ||
									!password.match(special)
								) {
									setError(
										"Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character"
									);
									return;
								}

								resetPassword({
									variables: {
										email: email,
										password: password,
										code: code,
									},
								});
							}}
						>
							<Input
								label="Code"
								type="text"
								placeholder="verification Code"
								required
								value={code}
								onChange={(e) => {
									setCode(e.target.value);
								}}
							/>
							<Input
								label="New Password"
								placeholder="New Password"
								type="password"
								required
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
							<strong style={{ fontSize: 10, lineHeight: 0.4 }}>
								<span className={password.length >= 8 && "text-indigo-500"}>
									<i
										className={`fad ${
											password.length >= 8 ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									8 Characters{" "}
								</span>
								|{" "}
								<span
									className={
										password.match(upperCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fad ${
											password.match(upperCaseLetters) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Upper Case{" "}
								</span>
								|{" "}
								<span
									className={
										password.match(lowerCaseLetters) && "text-indigo-500"
									}
								>
									<i
										className={`fad ${
											password.match(lowerCaseLetters) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Lower Case{" "}
								</span>
								|{" "}
								<span className={password.match(special) && "text-indigo-500"}>
									<i
										className={`fad ${
											password.match(special) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Special Character{" "}
								</span>
								|{" "}
								<span className={password.match(numbers) && "text-indigo-500"}>
									<i
										className={`fad ${
											password.match(numbers) ? "fa-check" : "fa-times"
										}`}
									></i>{" "}
									Number{" "}
								</span>
							</strong>
							<div className="mt-6">
								<button
									type="submit"
									className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									<span className="absolute left-0 inset-y-0 flex items-center pl-3">
										<LockClosedIcon
											className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
											aria-hidden="true"
										/>
									</span>
									Reset Password
								</button>
							</div>
						</Form>
					</>
				)}
			</div>
		</div>
	);
};

ForgotPassword.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	onUserInfoCollected: PropTypes.func.isRequired,
};

export default ForgotPassword;
