import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import appRoutes from "routes.js";
import Navbar from "./../components/Navbar";

import { DateTime } from "luxon";

export default function Public() {
	React.useEffect(() => {}, []);
	const getRoutes = (appRoutes) => {
		return appRoutes.map((prop, key) => {
			if (prop.layout === "/") {
				return (
					<Route
						exact
						path={prop.path}
						render={(props) => (
							<prop.component
								{...props}
								onUserInfoCollected={(user) => {
									localStorage.setItem(
										"user",
										JSON.stringify({
											...user,
											expiration: DateTime.now().plus({ hours: 2 }).toISO(),
										})
									);
								}}
							/>
						)}
						key={key}
					/>
				);
			}
		});
	};

	return (
		<Fragment>
			<Navbar />
			<div className="dark:bg-gray-900">
				<Switch>{getRoutes(appRoutes)}</Switch>
			</div>
		</Fragment>
	);
}
