import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { SupportFooter, SocialFooter } from "../components/Footer";
import { SupportNavbar } from "../components/Navbar";
import appRoutes from "routes.js";

export default function Support() {
  const getRoutes = (appRoutes) => {
    return appRoutes.map((prop, key) => {
      if (prop.layout === "/help") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      }
    });
  };

  return (
    <div className="bg-white dark:bg-gray-900">
      <SupportNavbar />
      <main>
        <Switch>
          {getRoutes(appRoutes)}
          <Redirect from="*" to="/help/" />
        </Switch>
      </main>

      <SupportFooter>
        <SocialFooter />
      </SupportFooter>
    </div>
  );
}
